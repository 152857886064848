<template>
    <div class="index-card">
        <el-row :gutter="10">
            <el-col
                class="sm-card"
                :lg="12"
                :md="8"
                v-for="(item, index) in cardList"
                :key="index"
            >
                <el-card class="opt-head zy-card mb-1">
                    <div class="font-16 global-head po-relative pl-1">
                        {{ item.name }}
                    </div>

                    <div class=" ml-1 d-flex ai-center">
                        <div
                            class="icont"
                            :class="['iconfont', item.icon]"
                            :style="{ color: item.iconColor }"
                        ></div>
                        <div class="font-30">{{ item.num }}</div>
                    </div>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>
<script>
export default {
    data() {
        return {
            cardList: [
                {
                    icon: 'icon-xiaoqu',
                    iconColor: '',
                    name: '小区 (个)',
                    num: '',
                    flag: 'AcCount'
                },
                {
                    icon: 'icon-zhuhu',
                    iconColor: '',
                    name: '总户数 (户)',
                    num: '',
                    flag: 'CountUser'
                },

                {
                    icon: 'icon-xufei',
                    // iconColor: '#FFD400',
                    iconColor: '#3FDF59',
                    name: '已缴费用户 (户)',
                    num: '',
                    flag: 'havePay'
                },
                {
                    icon: 'icon-ic_user_zx',
                    // iconColor: '#3FDF59',
                    name: '终端在线状态 (台)',
                    num: '',
                    flag: 'zhongDuanZaiXian'
                },
                {
                    icon: 'icon-yonghu',
                    // iconColor: '#E39D45',
                    name: '昨日使用用户 (户)',
                    num: '',
                    flag: 'YesterdayUsePeople'
                },
                {
                    icon: 'icon-zongliangtongji',
                    iconColor: '',
                    name: '昨日总用量 (Kw.H)',
                    num: '',
                    flag: 'YesterdayUseDangLiang'
                }
            ]
        }
    },
    created() {
        this.getCard()
    },
    methods: {
        async getCard() {
            const res = await this.$api.survey.getCard()
            console.log('res', res.data)
            this.cardList.forEach(item => {
                const keyItem = res.data[item.flag]
                console.log('keyItem', keyItem)
                item.num = keyItem
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.icont {
    width: 60px;
    height: 65px;
    font-size: 40px;
}
</style>
