<template>
    <div class="zykt-main p-1 h-100 box-border">
        <el-card class="zy-card ova yes-head h-100">
            <el-row :gutter="10">
                <el-col :lg="16"><index-line></index-line></el-col>

                <el-col :lg="8"><index-card></index-card> </el-col>

                <!-- 当前档位 -->
                <el-col :lg="16"><index-gear></index-gear></el-col>

                <!-- 缴费 -->
                <el-col :lg="8"> <index-pay></index-pay></el-col>
            </el-row>
        </el-card>
    </div>
</template>

<script>
import IndexLine from './components/index-line'
import IndexCard from './components/index-card'
import IndexGear from './components/index-gear'
import IndexPay from './components/index-pay'
export default {
    components: {
        IndexLine,
        IndexCard,
        IndexGear,
        IndexPay
    },
    data() {
        return {}
    }
}
</script>
