<template>
    <div class="echart-bar">
        <div ref="echartBar" :style="{ height: height }"></div>
    </div>
</template>
<script>
import { debounce } from '@/utils/tool'
export default {
    name: 'zy-echart-bar',
    props: {
        height: {
            type: String,
            default: '300px'
        },

        // x轴数据
        xAxisData: Array,

        // 数据
        seriesData: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        // const color = ['#5470C6', '#91CC75', '#FFDC60']

        const color = [
            {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                    {
                        offset: 0,
                        color: 'rgba(255,154,108,0.6)'
                    },
                    {
                        offset: 1,
                        color: 'rgba(255,154,108,0.2)'
                    }
                ]
            },
            {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                    {
                        offset: 0,
                        color: 'rgba(255,0,0,0.6)'
                    },
                    {
                        offset: 1,
                        color: 'rgba(255,0,0,0.2)'
                    }
                ]
            },
            {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                    {
                        offset: 0,
                        color: 'rgba(0,191,255)'
                    },
                    {
                        offset: 1,
                        color: 'rgba(0,191,255)'
                    }
                ]
            }
        ]

        const tooltip = {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                crossStyle: {
                    color: '#999'
                }
            }
        }

        const toolbox = {
            show: true,
            feature: {
                dataZoom: {
                    yAxisIndex: 'none'
                },
                dataView: { readOnly: false },
                magicType: { type: ['line', 'bar'] },
                restore: {},
                saveAsImage: {}
            }
        }

        const grid = {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        }

        const legend = {
            data: this.legendData
        }

        const xAxis = {
            type: 'category',
            data: this.xAxisData,
            axisLabel: { interval: 0, rotate: 30 }
        }

        const yAxis = [
            {
                type: 'value',
                name: '压力',
                // min: 0,
                // max: 100000,
                // interval: 15000,
                axisLabel: {
                    formatter: '{value} Kw/H'
                }
            },
            {
                type: 'value',
                name: '温度',
                min: 0,
                max: 40,
                interval: 5,
                axisLabel: {
                    formatter: '{value} °C'
                }
            }
        ]
        const series = this.seriesData

        return {
            option: {
                color,
                tooltip,
                toolbox,
                grid,
                legend,
                xAxis,
                yAxis,
                series
            }
        }
    },

    methods: {
        // echarts-自适应
        handleEchartResize() {
            this.chart.resize()
        }
    },
    mounted() {
        const _this = this

        // 初始化echarts
        _this.chart = this.$echarts.init(_this.$refs.echartBar)

        // 注入配置
        _this.chart.setOption(_this.option)

        _this.echartDebounce = debounce(100, _this.handleEchartResize)

        // 监听resize事件
        window.addEventListener('resize', _this.echartDebounce)
    },
    beforeDestroy() {
        // 销毁监听事件
        window.removeEventListener('resize', this.echartDebounce)
        this.chart.dispose()
    }
}
</script>
