<template>
    <el-card class="opt-head zy-card mb-1">
        <div class="d-flex jc-between">
            <div class="font-16 global-head po-relative pl-1 mb-2">总用量</div>
            <div class="">
                <el-button type="primary" plain @click="sevdayClick">
                    近七天
                </el-button>
                <el-button type="primary" plain @click="thidayClick">
                    近三十天
                </el-button>
            </div>
        </div>
        <zy-echart-linebar
            :xAxisData="xAxisData"
            :seriesData="seriesData"
            v-if="seriesData.length"
        ></zy-echart-linebar>
    </el-card>
</template>

<script>
import ZyEchartLinebar from '@/components/echarts/zy-echart-linebar'
export default {
    components: { ZyEchartLinebar },
    data() {
        return {
            xAxisData: [],
            seriesData: []
        }
    },

    created() {
        this.sevdayClick()
    },
    methods: {
        // 七天
        async sevdayClick() {
            this.xAxisData = []
            this.seriesData = []
            const startTime = '7'
            const res = await this.$api.survey.getLineBar(startTime)
            const arr = [
                {
                    name: '最低温',
                    type: 'line',
                    yAxisIndex: 1,
                    data: []
                },
                {
                    name: '最高温',
                    type: 'line',
                    yAxisIndex: 1,
                    data: []
                },
                {
                    name: '当量总用量',
                    type: 'line',
                    data: []
                }
            ]

            res.data.forEach(item => {
                arr[0].data.push(item.high)
                arr[1].data.push(item.low)
                arr[2].data.push(item.daily)
                this.xAxisData.push(item.record_time)
                this.seriesData = arr
            })
        },

        // 三十天
        async thidayClick() {
            this.xAxisData = []
            this.seriesData = []
            const startTime = '30'
            const res = await this.$api.survey.getLineBar(startTime)
            const arr = [
                {
                    name: '最低温',
                    type: 'line',
                    yAxisIndex: 1,
                    data: []
                },
                {
                    name: '最高温',
                    type: 'line',
                    yAxisIndex: 1,
                    data: []
                },
                {
                    name: '当量总用量',
                    type: 'line',
                    data: []
                }
            ]

            res.data.forEach(item => {
                arr[0].data.push(item.high)
                arr[1].data.push(item.low)
                arr[2].data.push(item.daily)
                this.xAxisData.push(item.record_time)

                this.seriesData = arr
            })
        }
    }
}
</script>
