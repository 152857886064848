<template>
    <div class="datav-echarts-pie">
        <div ref="datavEchartsPie" :style="{ height: height }"></div>
    </div>
</template>

<script>
// 防抖
import { debounce } from '@/utils/tool'
export default {
    name: 'datavEchartsPie',
    props: {
        height: {
            type: String,
            required: true,
            default: '500px'
        },
        seriesData: {
            required: true,
            type: Object
        }
    },
    data() {
        return {
            // echarts配置
            echartsOpt: {
                title: {
                    show: true,
                    text: this.name,
                    textStyle: {
                        fontSize: '14',
                        color: '#000',
                        fontWeight: 400
                    },
                    left: 'center'
                },
                tooltip: {
                    trigger: 'item',
                    left: 10,
                    formatter: '{b}:{c}<br/>({d}%)'
                },
                legend: {
                    orient: 'vertical',
                    left: 'left',
                    textStyle: {
                        color: '#000',
                        fontSize: '12'
                    }
                },
                color: ['#b8d38f', '#f1b8f1', '#E9D7B3', '#f3d751'],
                series: [
                    {
                        data: this.seriesData.data,
                        type: 'pie',
                        radius: ['50%', '65%'],
                        center: ['50%', '50%'],
                        labelLine: { show: false },
                        label: {
                            normal: {
                                show: false,
                                formatter: '{b}:{c}({d}%)'
                            }
                        },
                        itemStyle: {
                            emphasis: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0,0,0,0.5)'
                            }
                        }
                    }
                ]
            }
        }
    },
    methods: {
        // echarts-自适应
        handleEchartResize() {
            this.chart.resize()
        }
    },
    mounted() {
        const _this = this

        // 初始化echarts
        _this.chart = this.$echarts.init(_this.$refs.datavEchartsPie)

        // 注入配置
        _this.chart.setOption(_this.echartsOpt)

        _this.echartDebounce = debounce(100, _this.handleEchartResize)

        // 监听resize事件
        window.addEventListener('resize', _this.echartDebounce)
    },
    beforeDestroy() {
        // 销毁监听事件
        window.removeEventListener('resize', this.echartDebounce)
        this.chart.dispose()
    }
}
</script>
