<template>
    <el-card class="zy-card opt-head">
        <div class="font-16 global-head po-relative pl-1 mb-2">
            缴费 && 在线
        </div>
        <el-row :gutter="10">
            <el-col :span="12">
                <zy-echart-pie
                    height="300px"
                    :seriesData="payData"
                    v-if="payData.data.length"
                ></zy-echart-pie>
            </el-col>
            <el-col :span="12">
                <zy-echart-pie
                    height="300px"
                    :seriesData="onLineData"
                    v-if="onLineData.data.length"
                ></zy-echart-pie>
            </el-col>
        </el-row>
    </el-card>
</template>

<script>
import ZyEchartPie from '@/components/echarts/zy-echart-pie'
export default {
    components: { ZyEchartPie },
    data() {
        return {
            //  缴费
            payData: {},

            onLineData: {}
        }
    },
    created() {
        this.getLine()
    },
    methods: {
        async getLine() {
            const res = await this.$api.survey.getCard()

            const payArr = {
                data: [
                    {
                        name: '已缴费',
                        value: res.data.jiaoFei.havePay
                    },
                    {
                        name: '未缴费',
                        value: res.data.jiaoFei.haveNotPay
                    },
                    {
                        name: '欠费',
                        value: res.data.jiaoFei.qianFei
                    }
                ]
            }
            const arr = {
                data: [
                    {
                        name: '在线',
                        value: res.data.onlineStatus.online
                    },
                    {
                        name: '不在线',
                        value: res.data.onlineStatus.offLine
                    }
                ]
            }
            this.payData = payArr
            this.onLineData = arr
        }
    }
}
</script>
